import React, { useEffect, useState } from "react";
import './component-css/navbar.css'
import { NavLink } from "react-router-dom";
import axios from "axios";

function Navbar() {
    const initialRes = window.screen.width > 990 ? "" : "collapse";
    const [img, setImg] = useState("");
    const [resolution, setResolution] = useState(initialRes);
    const [inicio, setInicio] = useState("");
    const [productos, setProductos] = useState("");
    const [recetas, setRecetas] = useState("");
    const [blog, setBlog] = useState("");
    const [nosotros, setNosotros] = useState("");
    const [contacto, setcontacto] = useState();
    useEffect(() => {
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/navbar')
            .then((res) => {
                setImg(res.data.navbar.img);
                const initialUrl = window.location.href.split('/')[window.location.href.split('/').length - 1];
                checkRes(initialUrl === "" ? "inicio" : initialUrl)
            });
    }, []);
    function checkRes(origin) {
        window.screen.width > 990 ? setResolution("") : setResolution("collapse");
        switch (origin) {
            case origin = "inicio":
                cleanUnderline();
                setInicio("underline");
                break;
            case origin = "productos":
                cleanUnderline();
                setProductos("underline");
                break;
            case origin = "recetas":
                cleanUnderline();
                setRecetas("underline");
                break;
            case origin = "blog":
                cleanUnderline();
                setBlog("underline");
                break;
            case origin = "nosotros":
                cleanUnderline();
                setNosotros("underline");
                break;
            case origin = "contacto":
                cleanUnderline();
                setcontacto("underline");
            default:
                break;
        }
    }

    function cleanUnderline() {
        setInicio("");
        setProductos("");
        setRecetas("");
        setBlog("");
        setNosotros("");
        setcontacto("");
    }
    return (
        <nav className=" fixed
    w-full
    flex flex-wrap
    items-center
    justify-between
    py-2
    bg-base
    text-white
    hover:text-white
    focus:text-gray-400
    shadow-lg
    navbar navbar-expand-lg navbar-light
    ">
            <div className="container-fluid w-full flex flex-wrap items-center justify-between px-4">
                <button className="
        navbar-toggler
        text-white-500
        border-0
        hover:shadow-none hover:no-underline
        py-2
        px-2.5
        bg-transparent
        focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline
      " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars"
                        className="w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="currentColor"
                            d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
                        </path>
                    </svg>
                </button>
                <img className="
                pl-10
        navbar-toggler
        text-white-500
        border-0
        hover:shadow-none hover:no-underline
        py-2
        px-2.5
        bg-transparent
        focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline
      " style={{ maxHeight: "60px" }} src={img} alt="" />

                <div className="collapse navbar-collapse flex-grow items-center grid grid-cols-2 justify-between" id="navbarSupportedContent">

                    <div>
                        <img className="pl-4" id="lgDisplay" src={img} style={{ height: "50px" }} alt=""
                            loading="lazy" />
                    </div>
                    <div className='row-start-1'>
                        <ul className="navbar-nav font-bold flex flex-col pl-0 list-style-none mr-auto">
                            <li className="nav-item p-2">
                                <NavLink to='/'><div onClick={() => checkRes("inicio")} data-bs-toggle={resolution} data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" id="home" className={inicio + " decoration-2 nav-link text-white hover:underline focus:underline"}>Inicio</div></NavLink>
                            </li>
                            <li className="nav-item p-2">
                                <NavLink to='/productos'><div onClick={() => checkRes("productos")} data-bs-toggle={resolution} data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className={productos + " decoration-2 nav-link text-white hover:underline focus:underline"} >Productos</div></NavLink>
                            </li>
                            <li className="nav-item p-2">
                                <NavLink to='/recetas'><div onClick={() => checkRes("recetas")} data-bs-toggle={resolution} data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className={recetas + " decoration-2 nav-link text-white hover:underline focus:underline p-0"}>Recetas</div></NavLink>
                            </li>
                            <li className="nav-item p-2">
                                <NavLink to='/blog'><div onClick={() => checkRes("blog")} data-bs-toggle={resolution} data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className={blog + " decoration-2 nav-link text-white hover:underline focus:underline p-0"}>Blog</div></NavLink>
                            </li>
                            <li className="nav-item p-2">
                                <NavLink to='/nosotros'><div onClick={() => checkRes("nosotros")} data-bs-toggle={resolution} data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className={nosotros + " decoration-2 nav-link text-white hover:underline focus:underline p-0"}>Nosotros</div></NavLink>
                            </li>
                            <li className="nav-item p-2">
                                <NavLink to='/contacto'><div onClick={() => checkRes("contacto")} data-bs-toggle={resolution} data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className={contacto + " decoration-2 nav-link text-white hover:underline focus:underline p-0"}>Contacto</div></NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export { Navbar }