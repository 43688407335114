import axios from "axios";
import React, { useState } from "react";
import "../screens/css/general.css"

function RegisterForm() {
    let formData = {
        name: "",
        lastName: "",
        country: "",
        state: "",
        email: "",
        phone: "",
        message: ""
    }
    const [isNameValid, setIsNameValid] = useState(<div></div>);
    const [isLastNameValid, setIsLastNameValid] = useState(<div></div>);
    const [isCountryValid, setIsCountryValid] = useState(<div></div>);
    const [isMailValid, setIsMailValid] = useState(<div></div>);
    const [isMessageValid, setIsMessageValid] = useState(<div></div>);
    function validate(event, origin) {
        const value = event.target.value;
        switch (origin) {
            case origin = "name":
                if (validateText(value)) {
                    setIsNameValid(<div></div>);
                } else {
                    setIsNameValid(<span className="text-red-600 text-sm">Este campo es obligatorio. <br /> Mínimo 3 carácteres.</span>);
                }
                break;
            case origin = "lastName":
                if (validateText(value)) {
                    setIsLastNameValid(<div></div>);
                } else {
                    setIsLastNameValid(<span className="text-red-600 text-sm">Este campo es obligatorio. <br /> Mínimo 3 carácteres.</span>);
                }
                break;
            case origin = "country":
                if (validateText(value)) {
                    setIsCountryValid(<div></div>);
                } else {
                    setIsCountryValid(<span className="text-red-600 text-sm">Este campo es obligatorio. <br /> Mínimo 3 carácteres.</span>);
                }
                break;
            case origin = "email":
                if (validateEmail(value)) {
                    setIsMailValid(<div></div>);
                } else {
                    setIsMailValid(<span className="text-red-600 text-sm">Este campo es obligatorio. <br />Verifique que el formato del correo sea correcto.</span>);
                }
                break;
            case origin = "message":
                if (validateText(value)) {
                    setIsMessageValid(<div></div>);
                } else {
                    setIsMessageValid(<span className="text-red-600 text-sm">Este campo es obligatorio</span>);
                }
                break;
        }
    }
    function sendMessage() {
        let minRequired = true;
        if (formData.name.length < 2) {
            minRequired = false;
        }
        if (formData.lastName.length < 2) {
            minRequired = false;
        }
        if (formData.country.length < 2) {
            minRequired = false;
        }
        if (formData.email.length < 2) {
            minRequired = false;
        }
        if (formData.message.length < 2) {
            minRequired = false;
        }
        console.log(minRequired);
        if (minRequired) {
            axios.post('https://cz42eqvndb.execute-api.us-east-1.amazonaws.com/dev/sendMail', formData)
                .then(() => {
                    alert('Mensaje enviado')
                })
                .catch('Parece que no se envió el mensaje');
        } else {
            alert('Por favor complete correctamente todos los datos solicitados en el formulario.');
        }
    }
    function updateFormValues(event, origin) {
        const value = event.target.value;
        switch (origin) {
            case origin = "name":
                if (validateText(value)) {
                    formData.name = value;
                    setIsNameValid(<div></div>);
                }
                break;
            case origin = "lastName":
                if (validateText(value)) {
                    formData.lastName = value;
                    setIsLastNameValid(<div></div>);
                }
                break;
            case origin = "country":
                if (validateText(value)) {
                    formData.country = value;
                    setIsCountryValid(<div></div>);
                }
                break;
            case origin = "state":
                if (validateText(value)) {
                    formData.state = value;
                }
                break;
            case origin = "email":
                if (validateEmail(value)) {
                    formData.email = value;
                    setIsMailValid(<div></div>);
                }
                break;
            case origin = "phone":
                if (validatePhone(value)) {
                    formData.phone = value;
                }
                break;
            case origin = "message":
                if (validateText(value)) {
                    formData.message = value;
                    setIsMessageValid(<div></div>);
                }
                break;
        }
        console.log(formData);
    }
    function validateText(text) {
        let isString = false;
        if (typeof text === 'string' || text instanceof String) {
            isString = true;
        }
        if (text.length > 2 && isString) {
            return true;
        }
        return false;
    }

    function validatePhone(number) {
        let isNumber = false;
        if (!isNaN(number)) {
            isNumber = true;
        }
        if (isNumber && number.length > 9 && number.length < 15) {
            return true;
        }
        return false;
    }

    function validateEmail(email) {
        let validEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
        return validEmail;
    }

    return (
        <div className="block p-6 rounded-lg card-shadow bg-white max-w-md">
            <form className="grid grid-cols-2 gap-2">
                <div className="form-group mb-3 col-span-2 lg:col-span-1">
                    <input onBlur={(event => { validate(event, "name") })} onChange={(event) => { updateFormValues(event, "name") }} type="text" className="form-control block
        w-full
        px-3
        py-1.5
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput7"
                        placeholder="Nombre" />
                    {isNameValid}
                </div>
                <div className="form-group mb-3 col-span-2 lg:col-span-1">
                    <input onBlur={(event => { validate(event, "lastName") })} onChange={(event) => { updateFormValues(event, "lastName") }} type="text" className="form-control block
        w-full
        px-3
        py-1.5
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput8"
                        placeholder="Apellidos" />
                    {isLastNameValid}
                </div>
                <div className="form-group mb-3 col-span-2 lg:col-span-1">
                    <input onBlur={(event => { validate(event, "country") })} onChange={(event) => { updateFormValues(event, "country") }} type="text" className="form-control block
        w-full
        px-3
        py-1.5
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput7"
                        placeholder="País" />
                    {isCountryValid}
                </div>
                <div className="form-group mb-3 col-span-2 lg:col-span-1">
                    <input onChange={(event) => { updateFormValues(event, "state") }} type="text" className="form-control block
        w-full
        px-3
        py-1.5
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput7"
                        placeholder="Estado" />
                </div>
                <div className="form-group mb-3 col-span-2">
                    <input onBlur={(event => { validate(event, "email") })} onChange={(event) => { updateFormValues(event, "email") }} type="text" className="form-control block
        w-full
        px-3
        py-1.5
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput7"
                        placeholder="Correo electrónico" />
                    {isMailValid}
                </div>
                <div className="form-group mb-3 col-span-2">
                    <input onChange={(event) => { updateFormValues(event, "phone") }} type="text" className="form-control block
        w-full
        px-3
        py-1.5
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput7"
                        placeholder="Teléfono" />
                </div>
                <div className="form-group mb-3 col-span-2">
                    <textarea onBlur={(event => { validate(event, "message") })} onChange={(event) => { updateFormValues(event, "message") }}
                        className="
        form-control
        block
        w-full
        px-3
        py-1.5
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                        id="exampleFormControlTextarea13"
                        rows="3"
                        placeholder="Mensaje"
                    ></textarea>
                    {isMessageValid}
                </div>
                <button type="button" onClick={sendMessage} className="col-start-2
                w-full
                px-6
                py-2.5
                bg-orange-400
                text-white
                font-medium
                text-xs
                leading-tight
                uppercase
                rounded
                shadow-md
                hover:bg-orange-400 hover:shadow-lg
                focus:bg-orange-400 focus:shadow-lg focus:outline-none focus:ring-0
                active:bg-orange-600 active:shadow-lg
                transition
                duration-150
      ease-in-out">Enviar</button>
            </form>
        </div>
    );
}

export { RegisterForm };