import React from "react";

function StoreCard(props) {
    return (
        <div className="flex justify-center">
            <div className="rounded-lg shadow-lg bg-white max-w-sm pt-6 pl-6 pr-6">
                <img className="rounded-t-lg m-auto" src={props.imgUrl} alt="" />
                <div className="p-6">
                    <h5 className="text-gray-900 text-xl font-medium mb-2 text-center">{props.title}</h5>
                    <p className="text-gray-700 text-center mb-4">
                        {props.content}
                    </p>
                </div>
            </div>
        </div>
    )
}

export { StoreCard }