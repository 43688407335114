import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { StoreCard } from "./store-card";

function CarouselComp(props) {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <Carousel autoPlay={true} responsive={responsive}>
            {
                props.storeList.map((item, index) => {
                    if (props.storeList != "") {
                        return (<div key={item.store.name + index} className="p-5"><StoreCard key={item.store.name + index} title={item.store.name} content={item.store.location} imgUrl={item.store.img} /></div>)
                    }
                })
            }
        </Carousel>
    );
}

export { CarouselComp }