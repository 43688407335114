import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'tw-elements';

import ReactGA from 'react-ga4';
ReactGA.initialize("G-V04RV4CENL");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);