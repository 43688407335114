import React, { useEffect, useState } from "react";
import { RegisterForm } from "../components/register-form";
import { ContactCard } from "../components/contact-card";
import axios from "axios";
import "../components/component-css/loader.css";
import { ErrorScreen } from "./error-screen";

function Contact() {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [contInfo, setContInfo] = useState({
        youtube: {
            "isActive": false,
            "link": "",
            "text": ""
        },
        twitter: {
            "isActive": false,
            "link": "",
            "text": ""
        },
        mail: {
            "isActive": false,
            "link": "",
            "text": ""
        },
        phone: {
            "isActive": false,
            "link": "",
            "text": ""
        },
        tiktok: {
            "isActive": false,
            "link": "",
            "text": ""
        },
        facebook: {
            "isActive": false,
            "link": "",
            "text": ""
        },
        officeHours: {
            "isActive": false,
            "link": "",
            "text": ""
        },
        location: {
            "isActive": false,
            "link": "",
            "text": ""
        },
        instagram: {
            "isActive": false,
            "link": "",
            "text": ""
        },
        linkedin: {
            "isActive": false,
            "link": "",
            "text": ""
        }
    });
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/contacto')
            .then((res) => {
                setContInfo(res.data.contacto);
                setIsLoading(false);
            })
            .catch(() => setError(true))
            .finally(() => {
                if (isLoading) {
                    setIsLoading(false)
                }
            });
    }, []);
    return (
        <>
            {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
                    <div className="pt-16 mb-5">
                        <h1 className="font-bold  text-3xl text-center py-5">Contáctanos</h1>
                        <div className="flex flex-col md:flex-row justify-around">
                            <div className="mx-auto mb-5">
                                <RegisterForm />
                            </div>
                            <div className="mx-auto">
                                <div className="p-4"><ContactCard contInfo={contInfo} /></div>
                            </div>
                        </div>
                        <iframe className="mx-auto rounded-lg" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d484675.8614960961!2d-97.65039076456546!3d18.368706727454484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c595a9f74b51e5%3A0xb73352bd65774e52!2sZONTLE!5e0!3m2!1ses!2smx!4v1651603164522!5m2!1ses!2smx" width="90%" height="550" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
            }
        </>
    )
}

export { Contact }