import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Navbar } from './components/navbar'
import { Footer } from './components/footer'
import { Home } from './screens/home'
import { Products } from './screens/products'
import { Recipes } from './screens/recipes';
import { RecipeDetail } from './screens/recipe-detail';
import { BlogGeneral } from './screens/blog-general'
import { ArticuloBlog } from './screens/aticulo-blog';
import { AboutUs } from './screens/about-us';
import { Contact } from './screens/contact';
import { NotFound } from './screens/404-notfound';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/productos" element={<Products />} />
          <Route path="/recetas" element={<Recipes />} />
          <Route path="/recetas/:id" element={<RecipeDetail />} />
          <Route path="/blog" element={<BlogGeneral />} />
          <Route path="/blog/:id" element={<ArticuloBlog />} />
          <Route path="/nosotros" element={<AboutUs />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/home" element={<Navigate to="/" replace />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>

    </div>
  );
}

export default App;
