import React, { useEffect, useState } from "react";
import './css/general.css'
import axios from "axios";
import { a } from "react-router-dom";
import "../components/component-css/loader.css";
import { ErrorScreen } from "./error-screen";

function AboutUs() {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [section1, setSection1] = useState({ isActive: false, img: "", text: "" });
    const [section2, setSection2] = useState({ isActive: false, img: "", title: "", text: "" });
    const [section3, setSection3] = useState({ isActive: false, img: "", title: "", text: "" });
    const [section4, setSection4] = useState({ isActive: false, title: "", btnText: "", btnUrl: "" });
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/nosotros')
            .then((res) => {
                console.log(res.data.nosotros);
                const nosotros = res.data.nosotros;
                const sec1 = nosotros.initialImage;
                const sec2 = nosotros.orgChart;
                const sec3 = nosotros.region;
                const sec4 = nosotros.callToAction;
                setSection1({ isActive: sec1.isActive, img: sec1.img, text: sec1.text });
                setSection2({ isActive: sec2.isActive, img: sec2.img, title: sec2.title, text: sec2.text });
                setSection3({ isActive: sec3.isActive, img: sec3.img, title: sec3.title, text: sec3.text });
                setSection4({ isActive: sec4.isActive, title: sec4.title, btnText: sec4.btnText, btnUrl: sec4.btnUrl });
                setIsLoading(false);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    }, []);
    return (
        <>
            {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
                    <div style={{ minHeight: "100vh" }} className="pt-12">
                        <img style={{ minHeight: "70vh" }} className={(section1.isActive ? "" : "hidden ") + "w-full"} src={section1.img} alt="Foto de la empresa" />
                        <div className="cont">
                            <div className={(section1.isActive ? "" : "hidden ") + "my-9 mx-16 text-justify text-xl"}>
                                {section1.text}
                            </div>
                            <div className={(section2.isActive ? "" : "hidden ")}>
                                <h2 className="font-bold  text-3xl text-center py-5">{section2.title}</h2>
                                <img className="mx-auto rounded-md" style={{ maxHeight: "650px" }} src={section2.img} alt="Organigrama" />
                                <div className="my-9 mx-16 text-center text-xl">
                                    {section2.text}
                                </div>
                            </div>
                            <div className={(section1.isActive ? "" : "hidden ")}>
                                <h2 className="font-bold  text-3xl text-center py-5">{section3.title}</h2>
                                <img className="mx-auto rounded-md w-full" src={section3.img} alt="Rgión de la mixteca" />
                                <div className="my-9 mx-16 text-justify text-xl">
                                    {section3.text}
                                </div>
                            </div>
                            <div className={(section1.isActive ? "" : "hidden ")}>
                                <div className="text-center text-4xl">
                                    {section4.title}
                                </div>
                                <div className="flex space-x-2 justify-center my-5">
                                    <a href={section4.btnUrl}>
                                        <button type="button" className="m-5 text-2xl inline-block px-10 py-5 bg-base text-white font-light leading-tight uppercase rounded shadow-md hover:bg-orange-600 hover:shadow-lg focus:bg-orange-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-600 active:shadow-lg transition duration-150 ease-in-out">
                                            {section4.btnText}
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export { AboutUs }