import axios from "axios";
import React, { useEffect, useState } from "react";
import "./css/general.css";
import "./css/articulo-blog.css";
import parse from "html-react-parser";
import { NavLink } from "react-router-dom";
import { ErrorScreen } from "./error-screen";

function ArticuloBlog() {
    const [error, setError] = useState(false);
    const [img, setImg] = useState("");
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [date, setDate] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/articulosCompletos')
            .then((res) => {
                console.log(res.data.articulosCompletos[0].article);
                setText(res.data.articulosCompletos[0].article.content);
                setTitle(res.data.articulosCompletos[0].article.title);
                setImg(res.data.articulosCompletos[0].article.img);
                setDate(res.data.articulosCompletos[0].article.date);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    }, []);
    return (
        <>
            {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
                    <div className="pt-12">
                        <img style={{ maxHeight: "110vh" }} className="w-full h-auto m-auto" src={img} alt="Receta" />
                        <h1 className="font-bold text-tgray  text-4xl text-center py-7">{title}</h1>
                        <div className="cont article-cont mb-5 px-6 pb-10 text-xl">
                            <span className="text-right text-2xl block my-5">{date}</span>
                            {parse(text)}
                            <div className="flex space-x-2 justify-center">
                                <NavLink to="/blog"><button type="button" className="my-10 inline-block px-6 py-2.5 bg-comp text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out">Regresar a Blog</button></NavLink>
                            </div>
                        </div>
                    </div>}
        </>
    )
}

export { ArticuloBlog }