import React from "react";
import { NavLink } from "react-router-dom";
import './component-css/product-card.css'

function ProductCard(props) {
    return (
        <div className="flex justify-center">
            <div className="flex flex-col md:flex-row md:max-w-2xl rounded-lg bg-white card-shadow p-6">
                <img className="  h-full md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg" src={props.productImg} alt="" />
                <div className="p-6 flex flex-col justify-around ">
                    <div>
                        <h2 className="text-tgray text-6xl pr-5 text-center font-medium mb-2">{props.title}</h2>
                        <p className="text-tgray mb-4 text-justify text-xl pr-5 mt-5">
                            {props.productDescription}
                        </p>
                    </div>
                    <div>
                        <h3 className="text-tgray font-bold text-md">Sugerencias de uso:</h3>
                        <div className={"suggestions-container"}>
                            {props.suggestions.length === 0 ? <div className="my-4 ">Lo sentimos no hay recetas para este producto.</div> : null}
                            {props.suggestions.map((item, i) => {
                                return (<div key={item.recipe.id + i} style={{ width: "33.3%" }} className="img-suggestion overflow-hidden h-full">
                                    <NavLink to={"/recetas/" + item.recipe.id}>
                                        <img style={{ height: "80px" }} className="rounded-md mt-3" src={item.recipe.img} alt="" />
                                        <h4 style={{ fontSize: "12px" }} className="text-justify  font-semibold 
                                         mt-1 p-2">{item.recipe.title}</h4>
                                    </NavLink>
                                </div>)
                            })
                            }
                            {/* <div className="flex items-center">
                                <NavLink to="/recetas"><button aria-label="Ir a recetas" className="font-bold p-2">&gt;</button></NavLink>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ProductCard }

