import React, { useState, useEffect } from "react";
import { ImageTitle } from "../components/image-title";
import axios from "axios";
import { NavLink } from "react-router-dom";
import "../components/component-css/loader.css";
import { ErrorScreen } from "./error-screen";


function Recipes() {
    const [error, setError] = useState(false);
    const [recipes, setRecipes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/recetas')
            .then((res) => {
                setRecipes(res.data.recetas);
                setIsLoading(false);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    }, []);
    return (
        <>
            {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
                    <div style={{ minHeight: "70vh" }} className="pt-24 mb-5">
                        {recipes.length === 0 ? <div className="text-2xl my-auto border-0 text-center">No hay recetas para mostrar.</div> : null}
                        <div className="cont grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                            {
                                recipes.map((item) => {
                                    return <div key={item.recipe.id} className="mx-auto">
                                        <NavLink to={"/recetas/" + item.recipe.id}><ImageTitle title={item.recipe.title} img={item.recipe.img} /></NavLink>
                                    </div>
                                })
                            }
                        </div>
                    </div>
            }
        </>
    )
}

export { Recipes }