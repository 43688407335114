import React, { useEffect, useState } from "react";
import { ImageTitle } from "../components/image-title";
import axios from "axios";
import { NavLink } from "react-router-dom";
import "../components/component-css/loader.css";
import { ErrorScreen } from "./error-screen";

function BlogGeneral() {
    const [error, setError] = useState(false);
    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/blog')
            .then((res) => {
                setArticles(res.data.blog);
                setIsLoading(false);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    }, []);
    return (
        <>
            {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
                    <div style={{ minHeight: "70vh" }} className="pt-24 mb-5">
                        {articles.length === 0 ? <div className="text-2xl my-auto border-0 text-center">No hay artículos para mostrar.</div> : null}
                        {/* <h2 className="font-bold  text-2xl text-center py-5">Blog</h2> */}
                        <div className="cont grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                            {
                                articles.map((item) => {
                                    return <div key={item.article.title} className="mx-auto">
                                        <NavLink to={"/blog/" + item.article.id}><ImageTitle title={item.article.title} img={item.article.img} /></NavLink>
                                    </div>
                                })
                            }
                        </div>
                    </div>
            }
        </>
    )
}

export { BlogGeneral }