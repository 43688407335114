import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import './css/general.css'
import "./css/recipe-detail.css"
import parse from "html-react-parser";
import { ErrorScreen } from "./error-screen";

function RecipeDetail() {
    const [error, setError] = useState(false);
    const [img, setImg] = useState("");
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/recetasCompletas')
            .then((res) => {
                // console.log(res.data.recetasCompletas[0].recipe);
                res.data.recetasCompletas.forEach(element => {
                    if (element.recipe.id == window.location.pathname.split("/")[2]) {
                        console.log(element)
                        setText(element.recipe.content);
                        setTitle(element.recipe.title);
                        setImg(element.recipe.img);
                    }
                })
                // setText(res.data.recetasCompletas[0].recipe.content);
                // setTitle(res.data.recetasCompletas[0].recipe.title);
                // setImg(res.data.recetasCompletas[0].recipe.img);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    }, []);
    return (
        <>
            {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
                    <div className="pt-12">
                        <img style={{ maxHeight: "110vh" }} className="w-full h-auto m-auto" src={img} alt="Receta" />
                        <h1 className="font-bold text-tgray p-5  text-4xl text-center py-7">{parse(title)}</h1>
                        <div className="cont recipe-cont mb-5 lg:px-16 text-xl">
                            {parse(text)}
                            <div className="flex space-x-2 justify-center">
                                <NavLink to="/recetas"><button type="button" className="my-10 inline-block px-6 py-2.5 bg-comp text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out">Regresar a Recetas</button></NavLink>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export { RecipeDetail }