import React, { useEffect, useState } from "react";
import './css/general.css';
import { ImageTitle } from '../components/image-title';
import { CarouselComp } from '../components/carousel';
import { NavLink } from "react-router-dom";
import "../components/component-css/loader.css";
import axios from "axios";
import { ErrorScreen } from "./error-screen";


function Home() {
    const [error, setError] = useState(false);
    const [mainImage, setMainImage] = useState({ isActive: true, url: "" });
    const [section1, setSection1] = useState({ isActive: false, title: "", text: "", sideImage: "./img/cargando.png" });
    const [section2, setSection2] = useState({ isActive: false, title: "", text: "", certifications: [""] });
    const [section3, setSection3] = useState({ isActive: false, title: "", recipes: [""] });
    const [section4, setSection4] = useState({ isActive: false, title: "", storeList: [""] });
    const [section5, setSection5] = useState({ isActive: false, title: "", articles: [""] });
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/inicio')
            .then((res) => {
                const base = res.data.inicio;
                const sec1 = base.nosotros;
                const sec2 = base.certificaciones;
                const sec3 = base.ultimasRecetas;
                const sec4 = base.tiendas;
                const sec5 = base.articulosRecientes;
                setMainImage({ isActive: base.imagenInicial.isActive, url: base.imagenInicial.url });
                setSection1({ isActive: sec1.isActive, title: sec1.cardTitle, text: sec1.cardText, sideImage: sec1.imageUrl });
                setSection2({ isActive: sec2.isActive, title: sec2.sectionTitle, text: sec2.sectionDesc, certifications: sec2.certifArray });
                setSection3({ isActive: sec3.isActive, title: sec3.sectionTitle, recipes: sec3.recipesList });
                setSection4({ isActive: sec4.isActive, title: sec4.sectionTitle, storeList: sec4.storeList });
                setSection5({ isActive: sec5.isActive, title: sec5.sectionTitle, articles: sec5.articleList });
                setIsLoading(false);
                console.log(base);
            }).catch(() => {
                setError(true);
            }).finally(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    }, []);
    return (
        <>
            {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
                    <div>
                        <img style={{ minHeight: "70vh" }} id="mainImg" className={(mainImage.isActive ? "" : "hidden ") + "w-full h-auto pt-12"} src={mainImage.url} alt="Imagen de productos zontle" />
                        <div className={(mainImage.isActive ? "" : "pt-16 ") + "grid grid-cols-2 gap-7 cont py-6"}>
                            <div className={(section1.isActive ? "" : "hidden ") + "bg-white border mx-3 lg:mx-14 border-gray-300 text-justify card-shadow p-8 md:p-14 rounded-md col-span-2 lg:col-span-1"}>
                                <h1 className="lg:text-4xl text-3xl text-center mb-6 text-tgray">{section1.title}</h1>
                                <p className="text-lg text-tgray">
                                    {section1.text}
                                </p>
                                <div className="flex space-x-2 justify-end mt-3">
                                    <NavLink to="/nosotros"><button type="button" className="inline-block px-6 py-2.5 bg-orange-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-orange-600 hover:shadow-lg focus:bg-orange-600 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">Saber más</button></NavLink>
                                </div>
                            </div>
                            <img className={(section1.isActive ? "" : "hidden ") + "my-auto rounded-md col-span-2 lg:col-span-1"} src={section1.sideImage} alt="Imagen del frente de la empresa" />
                        </div>
                        <div className={(section2.isActive ? "" : "hidden ") + "cont rounded-md py-10 bg-fgray"}>
                            <h2 className="text-white font-bold text-2xl text-center py-5">{section2.title}</h2>
                            <div className={"py-5 px-7 grid " + (section2.certifications.length === 1 ? "grid-cols-1" : "") + (section2.certifications.length === 2 ? "grid-cols-2" : "") + (section2.certifications.length > 2 ? "grid-cols-3" : "")}>
                                {
                                    section2.certifications.map((cert) => {
                                        if (section2.certifications != "") {
                                            return (<div key={cert.certification.name} className="bg-white p-3 mx-4 rounded-md">
                                                <img key={cert.certification.name} className="h-24 mx-auto" src={cert.certification.img} alt={cert.certification.name} />
                                            </div>)
                                        }
                                    })
                                }
                            </div>
                            <p className="text-center text-lg lg:px-40 text-white p-5">
                                {section2.text}
                            </p>
                        </div>
                        <div className={(section3.isActive ? "" : "hidden ") + "cont font-bold  text-2xl text-center py-5"}>
                            <h2 className="pb-5">{section3.title}</h2>

                            <div className="flex lg:justify-around flex-col items-center lg:flex-row">
                                {
                                    section3.recipes.map((recipe, i) => {
                                        if (section3.recipes != "") {
                                            return <div key={recipe.recipe.title + i} className="m-4">
                                                <NavLink to={"/recetas/" + recipe.recipe.id}><ImageTitle key={recipe.recipe.title + i} title={recipe.recipe.title} img={recipe.recipe.img} /></NavLink>
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className={(section4.isActive ? "" : "hidden") + "cont bg-comp rounded-md mb-5 pb-5"}>
                            <h2 className="font-bold  text-2xl text-center text-white py-5">{section4.title}</h2>
                            <CarouselComp storeList={section4.storeList} />
                        </div>
                        <div className={(section5.isActive ? "" : "hidden ") + "mb-11"}>
                            <h2 className="font-bold  text-2xl text-center py-5">Artículos recientes</h2>
                            <div className="flex lg:justify-around flex-col items-center lg:flex-row">
                                {
                                    section5.articles.map((item, i) => {
                                        if (section5.articles != "") {
                                            return (<div key={item.article.title + i} className="m-4">
                                                <NavLink to={"/blog/" + item.article.id}><ImageTitle key={item.article.title + i} title={item.article.title} img={item.article.img} /></NavLink>
                                            </div>)
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
            }

        </>
    )
}

export { Home }