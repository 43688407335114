import React, { useState, useEffect } from "react";
import { ProductCard } from "../components/product-card";
import axios from "axios";
import "../components/component-css/loader.css";
import { ErrorScreen } from "./error-screen";

function Products() {
    const [error, setError] = useState(false);
    const [prods, setProds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/productos')
            .then((res) => {
                let arrayProducts = res.data.productos;
                setProds(arrayProducts);
                setIsLoading(false);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
        , []);
    return (
        <>
            {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
                    <div className="pt-12">
                        {/* <h1 className="text-5xl text-tgray text-center">Conoce nuestros productos</h1> */}
                        <div className="my-10">
                            {
                                prods.map((item, i) => {
                                    return <div key={item.product.name + i} className="m-10"><ProductCard title={item.product.name} productDescription={item.product.description} productImg={item.product.img} suggestions={item.product.recommendations} /></div>
                                })
                            }
                        </div>
                    </div>}
        </>
    )
}

export { Products }